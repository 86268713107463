*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Relevant, helvetica, sans-serif;
  font-weight: 300;
}

h1 {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1;
}

h2 {
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 2.5rem;
}

h3 {
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.25rem;
}

h4, h5, h6 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
}

strong {
  font-weight: 500;
}

blockquote {
  font-size: 1.125rem;
  line-height: 1.75rem;
  position: relative;
}

@media (min-width: 640px) {
  blockquote {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

blockquote:before {
  content: "";
  z-index: -10;
  height: 4rem;
  width: 4rem;
  background: url("open-quote.737d7c8d.svg") center no-repeat;
  position: absolute;
  top: -1.5rem;
  left: 0;
}

code:before, code:after {
  content: "`";
}

pre {
  --tw-bg-opacity: 1;
  background-color: rgb(30 30 30 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: scroll;
}

pre code:before, pre code:after {
  content: "";
}

table {
  width: 100%;
}

table tr {
  border-top-width: 1px;
}

table th, table td {
  text-align: left;
  vertical-align: top;
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-right: .75rem;
}

figure {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

figcaption {
  opacity: .6;
  margin-top: .75rem;
  font-style: italic;
  font-weight: 500;
}

img ~ figcaption {
  margin-top: -.75rem;
}

:is(.richtext h1, .richtext h2, .richtext h3, .richtext h4, .richtext h5, .richtext h6, .richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr):not(:last-child) {
  margin-bottom: 1.5rem;
}

:is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h1, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h2, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h3, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h4, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h5, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h6 {
  margin-top: 3rem;
}

.richtext h2 a, .richtext h3 a, .richtext h4 a, .richtext p a, .richtext li a {
  overflow-wrap: break-word;
  text-underline-offset: 2px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.bg-id-yellow .richtext h2 a:hover, .bg-id-orange .richtext h2 a:hover, .bg-id-grey .richtext h2 a:hover, .bg-white .richtext h2 a:hover, .bg-id-yellow .richtext h3 a:hover, .bg-id-orange .richtext h3 a:hover, .bg-id-grey .richtext h3 a:hover, .bg-white .richtext h3 a:hover, .bg-id-yellow .richtext h4 a:hover, .bg-id-orange .richtext h4 a:hover, .bg-id-grey .richtext h4 a:hover, .bg-white .richtext h4 a:hover, .bg-id-yellow .richtext p a:hover, .bg-id-orange .richtext p a:hover, .bg-id-grey .richtext p a:hover, .bg-white .richtext p a:hover, .bg-id-yellow .richtext li a:hover, .bg-id-orange .richtext li a:hover, .bg-id-grey .richtext li a:hover, .bg-white .richtext li a:hover {
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
}

.journal .richtext h2 a:hover, .journal .richtext h3 a:hover, .journal .richtext h4 a:hover, .journal .richtext p a:hover, .journal .richtext li a:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.richtext h2 a:hover, .richtext h3 a:hover, .richtext h4 a:hover, .richtext p a:hover, .richtext li a:hover {
  --tw-text-opacity: 1;
  color: rgb(240 171 0 / var(--tw-text-opacity));
}

.richtext ul, .richtext ol {
  padding-left: 1.25rem;
}

.richtext ul > li {
  margin-top: .75rem;
  list-style-type: disc;
}

.richtext ol > li {
  margin-top: .75rem;
  list-style-type: decimal;
}

.richtext p {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.richtext.large p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
}

.richtext.small p, .richtext.small li, .richtext.small a {
  font-size: 1rem;
  line-height: 1.5rem;
}

.richtext hr {
  border-color: currentColor;
}

.preload .transition {
  transition-property: none !important;
}

.lazyload {
  opacity: 0;
}

.lazyloading, .lazyloaded {
  opacity: 1;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

header, .subheader {
  -webkit-backface-visibility: hidden;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: transform;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.bg-id-yellow + .bg-id-yellow, .bg-id-orange + .bg-id-orange, .bg-id-grey + .bg-id-grey, .bg-white + .bg-white, .checkbox:hover input ~ .checkmark + .bg-white, .checkbox input:focus ~ .checkmark + .bg-white {
  padding-top: 0 !important;
}

.checkbox:hover input ~ .checkmark .text-id-yellow, .checkbox input:focus ~ .checkmark .text-id-yellow {
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
}

.checkbox:hover input ~ .checkmark, .checkbox input:focus ~ .checkmark {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.checkbox input:checked ~ .checkmark {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.checkbox input:checked ~ .checkmark svg, .checkbox input:checked ~ .checkmark .radio {
  display: block;
}

#indicator.loading, #loader.loading {
  opacity: .8;
}

.bg-id-yellow .text-id-yellow, .bg-id-orange .text-id-yellow, .bg-id-grey .text-id-yellow, .bg-white .text-id-yellow, .bg-id-yellow .\!text-id-yellow, .bg-id-orange .\!text-id-yellow, .bg-id-grey .\!text-id-yellow, .bg-white .\!text-id-yellow {
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
}

.journal .text-id-yellow, .journal .\!text-id-yellow {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.iframe-container iframe {
  aspect-ratio: 3 / 2;
  height: 100%;
  width: 100%;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 830px) {
  .container {
    max-width: 830px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 768px) {
  .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (min-width: 1024px) {
  .container {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-left-2 {
  left: -.5rem;
}

.-right-2 {
  right: -.5rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-4 {
  bottom: 1rem;
}

.left-0 {
  left: 0;
}

.left-4 {
  left: 1rem;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-2 {
  top: .5rem;
}

.top-2\/4 {
  top: 50%;
}

.top-auto {
  top: auto;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-\[3\/2\] {
  aspect-ratio: 3 / 2;
}

.aspect-\[3\/4\] {
  aspect-ratio: 3 / 4;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-0 {
  height: 0;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[80vh\] {
  height: 80vh;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.min-h-\[80vh\] {
  min-height: 80vh;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-2 {
  width: .5rem;
}

.w-2\/4 {
  width: 50%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-3 {
  width: .75rem;
}

.w-3\/6 {
  width: 50%;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-y-16 {
  --tw-translate-y: -4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.columns-2 {
  columns: 2;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(30 30 30 / var(--tw-border-opacity));
}

.border-current {
  border-color: currentColor;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

.border-white\/50 {
  border-color: #ffffff80;
}

.bg-\[gold\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 215 0 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(30 30 30 / var(--tw-bg-opacity));
}

.bg-black\/\[0\.65\] {
  background-color: #1e1e1ea6;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-id-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(187 187 187 / var(--tw-bg-opacity));
}

.bg-id-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(255 88 0 / var(--tw-bg-opacity));
}

.bg-id-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(240 171 0 / var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-black {
  --tw-gradient-from: #1e1e1e var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1e1e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.fill-current {
  fill: currentColor;
}

.fill-white {
  fill: #fff;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-left {
  object-position: left;
}

.p-3 {
  padding: .75rem;
}

.p-px {
  padding: 1px;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.\!text-id-yellow {
  --tw-text-opacity: 1 !important;
  color: rgb(240 171 0 / var(--tw-text-opacity)) !important;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-id-grey {
  --tw-text-opacity: 1;
  color: rgb(187 187 187 / var(--tw-text-opacity));
}

.text-id-yellow {
  --tw-text-opacity: 1;
  color: rgb(240 171 0 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/60 {
  color: #fff9;
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-25 {
  opacity: .25;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-\[100ms\] {
  transition-delay: .1s;
}

.delay-\[150ms\] {
  transition-delay: .15s;
}

.delay-\[200ms\] {
  transition-delay: .2s;
}

.delay-\[250ms\] {
  transition-delay: .25s;
}

.delay-\[300ms\] {
  transition-delay: .3s;
}

.delay-\[350ms\] {
  transition-delay: .35s;
}

.delay-\[400ms\] {
  transition-delay: .4s;
}

.delay-\[450ms\] {
  transition-delay: .45s;
}

.delay-\[500ms\] {
  transition-delay: .5s;
}

.delay-\[50ms\] {
  transition-delay: 50ms;
}

.delay-\[550ms\] {
  transition-delay: .55s;
}

.delay-\[600ms\] {
  transition-delay: .6s;
}

.duration-500 {
  transition-duration: .5s;
}

.underline {
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}

@font-face {
  font-family: Relevant;
  font-weight: 300;
  src: url("relevant-lightweb.2dc8ec78.eot");
  src: local(Relevant), url("relevant-lightweb.1f7dafe3.woff") format("woff"), url("relevant-lightweb.3a7a67a4.ttf") format("truetype");
}

@font-face {
  font-family: Relevant;
  font-weight: 400;
  src: url("relevant-normalweb.f2f3383d.eot");
  src: local(Relevant), url("relevant-normalweb.e2a595d3.woff") format("woff"), url("relevant-normalweb.c18638b6.ttf") format("truetype");
}

@font-face {
  font-family: Relevant;
  font-weight: 500;
  src: url("relevant-mediumweb.bd572142.eot");
  src: local(Relevant), url("relevant-mediumweb.ca9337a8.woff") format("woff"), url("relevant-mediumweb.ebcc54d7.ttf") format("truetype");
}

.bg-id-yellow .hover\:\!text-id-yellow:hover, .bg-id-orange .hover\:\!text-id-yellow:hover, .bg-id-grey .hover\:\!text-id-yellow:hover, .bg-white .hover\:\!text-id-yellow:hover, .bg-id-yellow .hover\:text-id-yellow:hover, .bg-id-orange .hover\:text-id-yellow:hover, .bg-id-grey .hover\:text-id-yellow:hover, .bg-white .hover\:text-id-yellow:hover, .bg-id-yellow .hover\:\!text-id-yellow:hover, .bg-id-orange .hover\:\!text-id-yellow:hover, .bg-id-grey .hover\:\!text-id-yellow:hover, .bg-white .hover\:\!text-id-yellow:hover, .bg-id-yellow .hover\:text-id-yellow:hover, .bg-id-orange .hover\:text-id-yellow:hover, .bg-id-grey .hover\:text-id-yellow:hover, .bg-white .hover\:text-id-yellow:hover, .bg-id-yellow .hover\:\!text-id-yellow:hover, .bg-id-orange .hover\:\!text-id-yellow:hover, .bg-id-grey .hover\:\!text-id-yellow:hover, .bg-white .hover\:\!text-id-yellow:hover, .bg-id-yellow .hover\:text-id-yellow:hover, .bg-id-orange .hover\:text-id-yellow:hover, .bg-id-grey .hover\:text-id-yellow:hover, .bg-white .hover\:text-id-yellow:hover, .bg-id-yellow .hover\:\!text-id-yellow:hover, .bg-id-orange .hover\:\!text-id-yellow:hover, .bg-id-grey .hover\:\!text-id-yellow:hover, .bg-white .hover\:\!text-id-yellow:hover, .bg-id-yellow .hover\:text-id-yellow:hover, .bg-id-orange .hover\:text-id-yellow:hover, .bg-id-grey .hover\:text-id-yellow:hover, .bg-white .hover\:text-id-yellow:hover {
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
}

.journal .hover\:\!text-id-yellow:hover, .journal .hover\:text-id-yellow:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:bg-white:focus + .focus\:bg-white:focus {
  padding-top: 0 !important;
}

.focus\:bg-white:focus .text-id-yellow, .bg-id-yellow .group:focus-within .group-focus-within\:text-id-yellow, .bg-id-orange .group:focus-within .group-focus-within\:text-id-yellow, .bg-id-grey .group:focus-within .group-focus-within\:text-id-yellow, .bg-white .group:focus-within .group-focus-within\:text-id-yellow {
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
}

.journal .group:focus-within .group-focus-within\:text-id-yellow {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.bg-id-yellow .group:hover .group-hover\:text-id-yellow, .bg-id-orange .group:hover .group-hover\:text-id-yellow, .bg-id-grey .group:hover .group-hover\:text-id-yellow, .bg-white .group:hover .group-hover\:text-id-yellow {
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
}

.journal .group:hover .group-hover\:text-id-yellow {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.placeholder\:text-black::placeholder {
  --tw-text-opacity: 1;
  color: rgb(30 30 30 / var(--tw-text-opacity));
}

.placeholder\:text-slate-400\/60::placeholder {
  color: #94a3b899;
}

.placeholder\:text-white\/50::placeholder {
  color: #ffffff80;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-0:before {
  content: var(--tw-content);
  inset: 0;
}

.after\:ml-1:after {
  content: var(--tw-content);
  margin-left: .25rem;
}

.after\:text-red-500:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.after\:content-\[\"\*\"\]:after, .after\:content-\[\'\*\'\]:after {
  --tw-content: "*";
  content: var(--tw-content);
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.hover\:\!text-id-yellow:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(240 171 0 / var(--tw-text-opacity)) !important;
}

.hover\:text-id-yellow:hover {
  --tw-text-opacity: 1;
  color: rgb(240 171 0 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-underline-offset: 2px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

.focus\:translate-y-0:focus {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.group:focus-within .group-focus-within\:text-id-yellow {
  --tw-text-opacity: 1;
  color: rgb(240 171 0 / var(--tw-text-opacity));
}

.group:hover .group-hover\:scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:text-id-yellow {
  --tw-text-opacity: 1;
  color: rgb(240 171 0 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-75 {
  opacity: .75;
}

@media (min-width: 640px) {
  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:aspect-video {
    aspect-ratio: 16 / 9;
  }

  .sm\:h-\[80vh\] {
    height: 80vh;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:gap-x-6 {
    column-gap: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:right-12 {
    right: 3rem;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-2\/4 {
    width: 50%;
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:gap-x-12 {
    column-gap: 3rem;
  }

  .md\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pr-3 {
    padding-right: .75rem;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:opacity-0 {
    opacity: 0;
  }
}

@media (min-width: 830px) {
  .nav\:static {
    position: static;
  }

  .nav\:ml-4 {
    margin-left: 1rem;
  }

  .nav\:mt-0 {
    margin-top: 0;
  }

  .nav\:block {
    display: block;
  }

  .nav\:flex {
    display: flex;
  }

  .nav\:hidden {
    display: none;
  }

  .nav\:min-h-0 {
    min-height: 0;
  }

  .nav\:w-auto {
    width: auto;
  }

  .nav\:bg-transparent {
    background-color: #0000;
  }

  .nav\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .nav\:pt-0 {
    padding-top: 0;
  }

  .nav\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .lg\:right-24 {
    right: 6rem;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-start-9 {
    grid-column-start: 9;
  }
}

/*# sourceMappingURL=main.css.map */
